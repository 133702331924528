




































































































import { Component, Emit, Prop, Vue } from "vue-property-decorator";
import { ExamStudent } from "@/tool/_class";
import {
  buildExamAnswerPackage,
  uploadExamAnswerRecords,
} from "@/api/examResource";
import { getExamRoomList } from "@/api/examRoom";
import { getExamTimeList } from "@/api/examTime";
import { getSchoolList } from "@/api/school";
import { getExamPaperList } from "@/api/examPaper";
import { getExamProjectList } from "@/api/examProject";
import { getExamAnswerRecordList } from "@/api/examAnswerRecord";
import {
  ExamTime,
  ExamPaper,
  ExamRoom,
  ExamAnswerRecord,
} from "@/tool/interface-index";

class Params {
  multiple: boolean = true;
}

class MyFormData {
  examPaperId: string = "";
  fileHashCode: string = "";
  examAreaId: string = "";
  examAreaName: string = "";
  examSchoolName: string = "";
  examSchoolId: string = "";
  examRoomId: string = "";
  examRoomName: string = "";
  examProjectId: string = "";
  examProjectName: string = "";
  examTimeId: string = "";
  examTimeName: string = "";
  examStudentId: string = "";
  examAccount: string = "";
  examStudentName: string = "";
  examAreaNameRaw: string = "";
  examProjectNameRaw: string = "";
  examSchoolNameRaw: string = "";
  examRoomNameRaw: string = "";
  examTimeNameRaw: string = "";
  examAccountRaw: string = "";
}

@Component({
  name: "BuildExamAnswerPackageDialog",
  components: {},
})
export default class extends Vue {
  @Prop({ default: () => new Params() }) private params!: Params;
  @Emit("handleConfirmed") private handleConfirmed() {}

  private formData: MyFormData = new MyFormData();
  private fileList: /*{ name: string; url: string }*/ any[] = [];

  private dialogVisible: boolean = false;

  private examTimes: ExamTime[] = [];
  private examPapers: ExamPaper[] = [];
  private examRooms: ExamRoom[] = [];
  private examAnswerRecords: ExamAnswerRecord[] = [];

  private rules = {
    examTimeId: [
      {
        required: true,
        message: "请选择场次",
        trigger: "blur",
      },
    ],
    examPaperId: [
      {
        required: true,
        message: "请选择试卷",
        trigger: "blur",
      },
    ],
    examRoomId: [
      {
        required: true,
        message: "请选择考场",
        trigger: "blur",
      },
    ],
  };

  private async submitUpload() {
    await this.validate();
    await (this.$refs.upload as any).submit();
    this.fileList.splice(0, this.fileList.length);
    //
  }

  private get uploadActionUrl(): string {
    return "";
    //return `${process.env.VUE_APP_BASE_API}/iScoreReport/v1/upload/importScore`;
  }

  private uploading: boolean = false;
  private handleUploadRequest(params: any) {
    /*
    alert(
      `${JSON.stringify(params)}, ${JSON.stringify(
        (this.$refs.upload as any).uploadFiles
      )}`
    );
    */
    const uploadFiles: any[] = (this.$refs.upload as any).uploadFiles;
    const file = params.file;
    let fileName: string = "";
    uploadFiles.findIndex((item) => {
      let result: boolean = false;
      result = item.raw.uid == file.uid;
      if (result) {
        fileName = item.name;
      }
      return result;
    });
    let formData = new FormData();
    formData.append("file01", file, fileName);
    //formData.append(file.name, this.file, file.name);
    this.uploading = true;
    uploadExamAnswerRecords(
      this.formData as any /*{
        file01: file.name,
        examProjectId: project.examProjectId,
        examProjectName: project.examProjectName,
      }*/,
      formData
    )
      .then((res: any) => {
        //this.fileList.splice(0, this.fileList.length);
        /*
        if (res.code == 0) {
          this.$message({
            type: "success",
            message: "上传成功!",
          });
        }
        */
      })
      .catch((error) => {
        //this.fileList.splice(0, this.fileList.length);
        //this.$message.error(`上传失败,${error.msg || error!.message || ""}`);
      })
      .finally(() => {
        this.uploading = this.fileList.length > 0;
      });
  }

  private handleRemove(file: any, fileList: any[]) {
    console.log(file, fileList);
  }
  private handlePreview(file: any) {
    console.log(file);
  }

  private handleExamTimeIdChange(value: string) {}
  private handleExamPaperIdChange(value: string) {}
  private handleExamRoomIdChange(value: string) {}

  private async __init(examStudent: ExamStudent) {
    const resps: any[] = await Promise.all([
      getExamProjectList({
        examProjectId: examStudent.examProjectId,
        curPage: 1,
        pageSize: 1,
      }),
      getExamPaperList({
        examProjectId: examStudent.examProjectId,
        curPage: 1,
        pageSize: 999,
      }),
      getExamTimeList({
        examProjectId: examStudent.examProjectId,
        curPage: 1,
        pageSize: 999,
      }),
      getSchoolList({
        schoolId: examStudent.examSchoolId,
        curPage: 1,
        pageSize: 1,
      }),
      getExamRoomList({
        schoolId: examStudent.examSchoolId,
        curPage: 1,
        pageSize: 999,
      }),
      getExamAnswerRecordList({
        examStudentId: examStudent.examStudentId,
        examTimeId: examStudent.examTimeId,
        examProjectId: examStudent.examProjectId,
        curPage: 1,
        pageSize: 999,
      }),
    ]);
    this.examPapers = resps[1].data.items;
    this.examTimes = resps[2].data.items;
    this.examRooms = resps[4].data.items;
    this.examAnswerRecords = resps[5].data.items;

    this.formData = new MyFormData();

    this.formData.examProjectId = examStudent.examProjectId;

    if (resps[0].data.items.length == 1) {
      //项目信息
      this.formData.examProjectName = resps[0].data.items[0].examProjectName;
      this.formData.examProjectNameRaw = resps[0].data.items[0].examProjectName;
    }

    this.formData.examSchoolId = examStudent.examSchoolId;
    if (resps[3].data.items.length == 1) {
      //学校信息
      this.formData.examSchoolName = resps[3].data.items[0].schoolName;
      this.formData.examSchoolNameRaw = resps[3].data.items[0].schoolName;
      this.formData.examAreaId = resps[3].data.items[0].areaId;
      this.formData.examAreaName = resps[3].data.items[0].areaName;
      this.formData.examAreaNameRaw = resps[3].data.items[0].areaName;
    }
    this.formData.examTimeId = examStudent.examTimeId;
    this.formData.examRoomId = examStudent.examRoomId;

    this.formData.examStudentId = examStudent.examStudentId;
    this.formData.examAccount = examStudent.examAccount;
    this.formData.examStudentName = examStudent.examStudentName;
    this.formData.examAccountRaw = examStudent.examAccount;
    this.formData.examPaperId = examStudent.examPaperId;
    if (!this.formData.examPaperId) {
      this.examAnswerRecords.findIndex((item) => {
        let result: boolean = false;
        result = item.examPaperId !== "";
        if (result) {
          this.formData.examPaperId = item.examPaperId;
        }
        return result;
      });
    }
  }
  async show(examStudent: ExamStudent) {
    await this.__init(examStudent);
    this.dialogVisible = true;
  }
  private async validate() {
    await (this.$refs.buildExamAnswerPackageDialogForm as any).validate();
    //
    this.examTimes.findIndex((item) => {
      let result = item.examTimeId == this.formData.examTimeId;
      if (result) {
        this.formData.examTimeName = item.examTimeName;
        this.formData.examTimeNameRaw = item.examTimeName;
      }
      return result;
    });
    //
    this.examPapers.findIndex((item) => {
      let result = item.examPaperId == this.formData.examPaperId;
      if (result) {
        //
      }
      return result;
    });
    //
    this.examRooms.findIndex((item) => {
      let result = item.examRoomId == this.formData.examRoomId;
      if (result) {
        this.formData.examRoomName = item.examRoomName;
        this.formData.examRoomNameRaw = item.examRoomName;
      }
      return result;
    });
  }
  private async doOkClick() {
    await this.validate();
    //
    await buildExamAnswerPackage(this.formData);
    //
    this.handleConfirmed();
    this.dialogVisible = false;
  }
}
