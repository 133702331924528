import request from '@/utils/request';
/**
 * 生成答案包
 * @param params 
 * @returns 
 */
export const buildExamAnswerPackage = (params: {
    examProjectId: string,
    examProjectName: string,
    examTimeId: string,
    examTimeName: string,
    examStudentId: string,
    examAccount: string,
    examAreaId: string,
    examAreaName: string,
    examSchoolId: string,
    examSchoolName: string,
    examRoomId: string,
    examRoomName: string,
    examPaperId: string,
    examStudentName: string,
    examAreaNameRaw: string,
    examProjectNameRaw: string,
    examSchoolNameRaw: string,
    examRoomNameRaw: string,
    examTimeNameRaw: string,
    examAccountRaw: string
}) => request({
    url: `/v1/exam/buildExamAnswerPackage`,
    method: 'post',
    headers: {
        "Content-Type": "application/json"
    },
    data: params
})

/**
 * 批量生成答案包
 * @param params 
 * @returns 
 */
export const buildExamAnswerPackages = (params: {
    examProjectId: string,
    examStudentIds?: string[]
}) => request({
    url: `/v1/exam/buildExamAnswerPackages`,
    method: 'post',
    headers: {
        "Content-Type": "application/json"
    },
    data: params
})

export const uploadExamAnswerRecords = (params: {
    examProjectId: string,
    examProjectName: string,
    examTimeId: string,
    examTimeName: string,
    examStudentId: string,
    examAccount: string,
    examAreaId: string,
    examAreaName: string,
    examSchoolId: string,
    examSchoolName: string,
    examRoomId: string,
    examRoomName: string,
    examPaperId: string,
    examStudentName: string,
}, data: any) => request({
    url: `/v1/upload/examAnswerRecords`,
    method: 'post',
    headers: {
        "Content-Type": "multipart/form-data"
    },
    params,
    data
})