import request from '@/utils/request'

export const getExamAnswerRecordList = (params: {
  examAnswerRecordId?: string,
  examTimeId?: string,
  examStudentId?: string,
  examAccount?: string,
  examProjectId?: string,
  examProjectSchoolId?: string,
  pageSize: number,
  curPage: number
}) =>
  request({
    // 获取作答记录列表
    url: '/v1/examAnswerRecordList',
    method: 'GET',
    params
  })