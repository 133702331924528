import request from '@/utils/request'

export const getExamTimeList = (params: { examProjectId?: string, pageSize: number, curPage: number }) =>
  request({
    // 获取考试场次列表
    url: '/v1/examTimeList',
    method: 'GET',
    params: params
  })

// export const addSchool = (data: any) =>
//   request({
//     // 添加/更新学校
//     url: '/v1/School',
//     method: 'POST',
//     data
//   })

// export const querySchool = (schoolId: string) =>
//   request({
//     //  获取学校by schoolId
//     url: `/v1/School/${schoolId}`,
//     method: 'GET'
//   })

// export const deleteSchool = (schoolId: string) =>
//   request({
//     // 删除学校by schoolId
//     url: `/v1/School/${schoolId}`,
//     method: 'DELETE'
//   })
